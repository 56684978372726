// src/components/Rent.jsx

import { AnimatePresence, motion } from "framer-motion";
import {
	FaBatteryFull,
	FaCamera,
	FaCog,
	FaFilter,
	FaLightbulb,
	FaTools,
	FaTv,
} from "react-icons/fa";
import React, { useState } from "react";

import { BsLaptop } from "react-icons/bs";
import { GiDeliveryDrone } from "react-icons/gi";
import Modal from "./Modal"; // Import the Modal component
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar"; // Assuming you already have a Navbar component

// Rental Items Array with 'category' field and updated 'imgSrc'
const rental = [
	// Existing Rental Items (Assigned to "More" category)
	{
		imgSrc: "/rental1.png",
		name: "Arri Alexa SXT Plus Package",
		category: "More",
	},
	{
		imgSrc: "/rent2.png",
		name: "Chrosziel MB450-R1",
		category: "More",
	},
	{
		imgSrc: "/rent4.png",
		name: "Arri SXT Original Case",
		category: "More",
	},
	{
		imgSrc: "/rent5.png",
		name: "Arri Alexa Internally FSND Filter Set",
		category: "More",
	},
	{
		imgSrc: "/rent6.png",
		name: "Blueshape Granite 290 Wh and Charger",
		category: "More",
	},
	{
		imgSrc: "/rent7.png",
		name: "Tv Logic 5 inch Monitor",
		category: "More",
	},
	{
		imgSrc: "/rent8.png",
		name: "Hollyland Mars 400 Pro Wireless SDI",
		category: "More",
	},
	{
		imgSrc: "/rent9.png",
		name: "Sachtler Video 18 S2 Head System",
		category: "More",
	},
	{
		imgSrc: "/lente.png",
		name: "Sony Cine Alta 4K PL/T2 Full Set of 6 Lenses",
		category: "Lenses",
	},
	{
		imgSrc: "/monitor.png",
		name: "Panasonic BT-LH910 LCD 9” Video Field Monitor",
		category: "Camera",
	},
	{
		imgSrc: "/rent10.png",
		name: "Sony FX6 Full-Frame Cinema Camera",
		category: "Camera",
	},
	{
		imgSrc: "/teklente.png",
		name: "Sony FE 24-70mm f/2.8 GM II Lens (Sony E)",
		category: "Lenses",
	},
	{
		imgSrc: "/camcorder.png",
		name: "Sony PXW-FS7 4K XDCAM Super35 Camcorder",
		category: "Camera",
	},
	{
		imgSrc: "/4lente.png",
		name: "Rokinon 24, 35, 50, 85mm T1.5 Cine DS Lens Bundle",
		category: "Lenses",
	},
	{
		imgSrc: "/teklentetjeter.png",
		name: "DJI Gimbal Ronin 1",
		category: "More",
	},
	{
		imgSrc: "/dron.png",
		name: "Drone DJI MAVIC 2 PRO",
		category: "Camera",
	},
	{
		imgSrc: "/drita.png",
		name: "2 x DAYLED 2000 DUAL COLOR",
		category: "More",
	},
	{
		imgSrc: "/tekdrit.png",
		name: "2 x DAYLED 1000 DUAL COLOR",
		category: "More",
	},
	{
		imgSrc: "/tjaterdrit.png",
		name: "2 x DAYLED 650 DUAL COLOR",
		category: "More",
	},
	{
		imgSrc: "/grid.png",
		name: "2 x EGG CRATE GRID FOR SOFTBOX",
		category: "Lighting",
	},
	{
		imgSrc: "/dritkatore.png",
		name: "2 x Lupo Softbox for Fresnels",
		category: "Lighting",
	},
	{
		imgSrc: "/stand.png",
		name: "COMPACT STAND - Lightweight Stand for Fixtures",
		category: "More",
	},
	{
		imgSrc: "/stand2.png",
		name: "MASTER STAND - Master Stand for Fixtures (Black, 12')",
		category: "More",
	},
	{
		imgSrc: "/renta11.png",
		name: "10 x DeSisti Magis 300/500/650W Fresnel Light with Stand Mount",
		category: "Lighting",
	},
	{
		imgSrc: "/atomos.png",
		name: "External Recorder Monitor Atomos Ninja Inferno",
		category: "More",
	},
	{
		imgSrc: "/appleretina.png",
		name: 'Apple 27" iMac Pro with Retina 5K Display',
		category: "More",
	},
	{
		imgSrc: "/macbookpro.png",
		name: "MacBook Pro 16-inch (Space Gray)",
		category: "More",
	},

	// New Rental Items Based on Provided List
	{
		imgSrc: "/DERA RENTAL.zip - 1.png",
		name: "Teradek Bolt 6 LT 750 3G-SDI/HDMI Wireless RX/TX Deluxe Kit (V-Mount)",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 2.png",
		name: 'OSEE 21.5" LCM215-HDR+ Field Monitor with V-Mount Battery Plate',
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 3.png",
		name: 'Impact PortaFrame Scrim Flag Kit (24 x 36")',
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 4.png",
		name: "DigitalFoto Solution Limited V360QB-3 Background Frame with Removable Diffuser",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 5.png",
		name: "NiSi ATHENA PL-E Adapter for PL Mount Lenses to Sony E Cameras",
		category: "Lenses",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 6.png",
		name: "Jackery Explorer 1000 Portable Power Station",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 7.png",
		name: "Tilta Nucleus-M Wireless Lens Control System",
		category: "Lenses",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 8.png",
		name: '1x Easyrig Vario 5 Strong with Gimbal Rig Vest and 5" Extended Arm, 22 to 55 lb Load Capacity',
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 9.png",
		name: "1x DJI RS 4 Pro Gimbal Stabilizer Combo",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 10.png",
		name: "2 x Hollyland Pyro Video Transmission Monitoring Kit",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 11.png",
		name: "1x Nanlux Evoke 2400B Bi-Color LED Monolight Kit with 45° Reflector and Light-Only Flight Case",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 12.png",
		name: "2x Nanlite PavoSlim 120C RGB LED Panel",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 13.png",
		name: "1x Nanlite PavoSlim 60C RGB LED Panel",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 14.png",
		name: "1x Aladdin ALL IN 1 Bi-Color RGB LED Panel Gaffer Kit",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 15.png",
		name: "Nanlite PavoTube II 30C RGB LED Tube Light (4', 4-Light Kit)",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 16.png",
		name: "Nanlite PavoBulb 10C Bi-Color RGBWW LED Bulb (4-Light Kit)",
		category: "Lighting",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 17.png",
		name: "DJI Mavic 3 Classic Drone with DJI RC Remote Controller",
		category: "Camera",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 18.png",
		name: "2x SmallRig L-Series/NP-F970 USB-C Rechargeable Camera Battery (Black)",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 19.png",
		name: "2x SmallRig L-Series / NP-F970 USB-C Battery (10,500mAh / 76.3Wh, Blue)",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 20.png",
		name: "2x SmallRig NP-F970, Capacity: 7800mAh, 7.2V, Dual-Battery and Charger Kit",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 21.png",
		name: "4 x Battery | V-Mount | 99Wh | Black",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 22.png",
		name: "4x Hedbox HED-BP95D BP-U Lithium-Ion Battery Pack (14.4V, 96.48Wh)",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 23.png",
		name: "2 x Hedbox NERO XL 14.8V Li-Ion V-Mount Battery (300Wh)",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 24.png",
		name: "2x BLUESHAPE BV290 GRANITE HDplus 290Wh V-Mount Battery",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 25.png",
		name: "1x Pro Studio Giant Sun Scrim Collapsible Frame Diffusion Kit with Bag (12x12'), TRP ULTRABOUNCE (Black/White, 12 x 12')",
		category: "More",
	},
	{
		imgSrc: "/DERA RENTAL.zip - 26.png",
		name: "1x Green screen 6m x 3m",
		category: "More",
	},
	// Excluded Images Without Names
	{
		imgSrc: "/IMG_8956.PNG",
		name: "Dera Film Toyota ", // No name provided
		category: "More",
	},
	{
		imgSrc: "/IMG_8955.PNG",
		name: "Dera Film Sprinter", // No name provided
		category: "More",
	},
];

// Categories Array grouped by 'category' field
const categories = [
	{
		name: "",
		imgSrc: "/IMG_9518.PNG", // Replace with representative image for this category
		items: rental.filter((item) => item.category === "Camera"),
	},
	{
		name: "",
		imgSrc: "/IMG_9521.PNG", // Replace with representative image for this category
		items: rental.filter((item) => item.category === "Lenses"),
	},
	{
		name: "",
		imgSrc: "/IMG_9519.PNG", // Replace with representative image for this category
		items: rental.filter((item) => item.category === "Lighting"),
	},
	{
		name: "",
		imgSrc: "/IMG_9520.PNG", // Replace with representative image for this category
		items: rental.filter((item) => item.category === "More"),
	},
];

const Rent = () => {
	// State to manage the modal visibility and current image
	const [showModal, setShowModal] = useState(false);
	const [currentImg, setCurrentImg] = useState("");
	const [currentName, setCurrentName] = useState("");

	// State to manage selected category
	const [selectedCategory, setSelectedCategory] = useState(null);

	// Handler to open the modal with the clicked image
	const openModal = (imgSrc, name) => {
		setCurrentImg(imgSrc);
		setCurrentName(name);
		setShowModal(true);
	};

	// Handler to select a category
	const selectCategory = (category) => {
		console.log(`Selected Category: ${category.name}`); // Debugging
		setSelectedCategory(category);
		window.scrollTo(0, 0); // Scroll to top when category is selected
	};

	// Handler to go back to categories
	const backToCategories = () => {
		setSelectedCategory(null);
		window.scrollTo(0, 0); // Scroll to top when going back
	};

	// Helper function to determine the appropriate icon
	const getIcon = (name) => {
		const lowerName = name.toLowerCase();

		// Monitor Icons
		if (lowerName.includes("monitor")) return <FaTv />;

		// Drone Icons
		if (lowerName.includes("drone") || lowerName.includes("mavic"))
			return <GiDeliveryDrone />;

		// Camera Icons
		if (
			lowerName.includes("camera") ||
			lowerName.includes("camcorder") ||
			lowerName.includes("sxt") ||
			lowerName.includes("fs7")
		) {
			return <FaCamera />;
		}

		// Laptop/Computer Icons
		if (
			lowerName.includes("laptop") ||
			lowerName.includes("macbook") ||
			lowerName.includes("imac") ||
			lowerName.includes("computer")
		) {
			return <BsLaptop />;
		}

		// Gimbal Icon
		if (lowerName.includes("gimbal")) return <FaCog />;

		// Softbox Icons
		if (lowerName.includes("softbox") || lowerName.includes("egg crate"))
			return <FaLightbulb />;

		// Stand Icons
		if (lowerName.includes("stand")) return <FaTools />;

		// Light Icons
		if (
			lowerName.includes("light") ||
			lowerName.includes("led") ||
			lowerName.includes("fresnel")
		)
			return <FaLightbulb />;

		// Battery Icon
		if (lowerName.includes("battery")) return <FaBatteryFull />;

		// Specific Brand Matches
		if (lowerName.includes("chrosziel")) return <FaFilter />;
		if (lowerName.includes("arri")) return <FaCamera />;

		// If no condition matches, return Gear Icon
		return <FaCog />;
	};

	return (
		<div className="bg-gray-100 text-gray-800 min-h-screen flex flex-col">
			{/* Navbar */}
			<Navbar />

			{/* Page Container */}
			<div className="container mx-auto px-6 py-20 flex-grow">
				{/* Title */}
				<motion.h2
					className="text-4xl md:text-5xl font-extrabold text-center my-8 md:my-16 text-red-500"
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.8 }}
				>
					{selectedCategory ? selectedCategory.name : "Rental Equipment"}
				</motion.h2>

				{/* Categories View */}
				{!selectedCategory && (
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
						{categories.map((category, index) => (
							<motion.div
								key={index}
								className="relative group cursor-pointer overflow-hidden rounded-lg  hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
								initial={{ opacity: 0, y: 50 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.6, delay: index * 0.1 }}
								onClick={() => selectCategory(category)}
							>
								{/* Category Image */}
								<img
									src={category.imgSrc}
									alt={category.name}
									className="w-full h-auto object-cover"
								/>

								{/* Overlay */}
								<div className="absolute inset-0  group-hover:bg-opacity-50 transition-opacity duration-300 pointer-events-none"></div>

								{/* Category Name */}
								<div className="absolute inset-0 flex items-center justify-center">
									<h3 className="text-white text-2xl md:text-3xl font-bold uppercase text-center px-2">
										{category.name}
									</h3>
								</div>
							</motion.div>
						))}
					</div>
				)}

				{/* Rental Items View */}
				{selectedCategory && (
					<div>
						{/* Back Button */}
						<button
							onClick={backToCategories}
							className="mb-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-300"
						>
							&larr; Back to Categories
						</button>

						{/* Rental Listing */}
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12">
							{selectedCategory.items.map((item, index) => {
								const icon = getIcon(item.name);

								return (
									<motion.div
										key={index}
										className="bg-white rounded-xl  overflow-hidden hover:shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out relative group"
										initial={{ opacity: 0, y: 50 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.6, delay: index * 0.1 }}
									>
										{/* Icon Container */}
										<motion.div
											className="absolute top-4 left-4 bg-red-500 text-white p-2 rounded-full shadow-lg"
											whileHover={{ scale: 1.2 }}
											transition={{ type: "spring", stiffness: 300 }}
										>
											{icon}
										</motion.div>

										{/* Image Container */}
										<div className="w-full h-0 pb-[125%] relative overflow-hidden">
											<motion.img
												src={item.imgSrc}
												alt={item.name}
												className="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
												loading="lazy"
												onClick={() => openModal(item.imgSrc, item.name)}
												whileHover={{ scale: 1.05 }}
												transition={{ duration: 0.3 }}
											/>
											{/* Logo Strip at Bottom */}
										</div>

										{/* Content */}
										<div className="p-4">
											<h3 className="text-lg font-semibold text-center text-red-500 transition-colors duration-300 group-hover:text-red-600">
												{item.name}
											</h3>
										</div>
									</motion.div>
								);
							})}
						</div>
					</div>
				)}
			</div>

			{/* Modal for Fullscreen Image */}
			<AnimatePresence>
				{showModal && (
					<Modal
						showModal={showModal}
						setShowModal={setShowModal}
						imgSrc={currentImg}
						name={currentName}
					/>
				)}
			</AnimatePresence>

			{/* Footer */}
			<footer className="w-full bg-black text-white py-10">
				<div className="container mx-auto text-center px-6">
					<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8 mb-6">
						<li>
							<NavLink
								to="/"
								exact
								className="hover:text-red-500 transition-colors duration-300"
							>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/about"
								className="hover:text-red-500 transition-colors duration-300"
							>
								About Us
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/locations"
								className="hover:text-red-500 transition-colors"
							>
								Locations
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/rental"
								className="hover:text-red-500 transition-colors duration-300"
							>
								Rental
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/contact"
								className="hover:text-red-500 transition-colors duration-300"
							>
								Contact
							</NavLink>
						</li>
					</ul>
					<p className="text-sm">
						© {new Date().getFullYear()} Dera Film. All rights reserved.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default Rent;
